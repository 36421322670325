#header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
}

#menu {
  position: absolute;
  top: 0;
  z-index: 12;
}


#portal-logo-wrapper {
  flex-wrap: wrap;
  position: fixed;
  z-index: 10;
  background-color: white;
  width: 100%;
  left: 0%;
  right: 0%;
  top: 0;
}
.header-menu {
  margin-top: 90px;
  display: flex;

  @media screen and (max-width:767px) {
    margin-top: 140px;
  }

  ul.vertical-menu {
    display: flex;
    justify-content: space-between;
    list-style: none;
    gap: 20px;
  }
}

body.plone-toolbar-top.userrole-manager, body.plone-toolbar-top.userrole-member {
  #portal-logo-wrapper {
    flex-wrap: wrap;
    position: fixed;
    z-index: 10;
    background-color: white;
    width: 100%;
    left: 0%;
    right: 0%;
    top: 40px;
    // margin-left:0;
  
    @media screen and (max-width:500px) {
      top: 0px;
    }

  }

  .header-menu {
    margin-top: 130px;
    display: flex;

  }
}

div.header-menu {
  padding-bottom: 1rem;
}



#slideshow-container {
  margin-right: 1.5%;
}

#block-left,
#block-right {
  width: 45%;
}


.mlpm_w .nav-small-links a {
  margin: 10px 0;
}

.toggle-button {
  display: flex;
  text-decoration: none;
  text-shadow: -.10px -.10px 0 black,
    .10px .10px black;
  
}

.toggle-button>span {
  color: $primary;
  font-size: 1rem;
}

.toggle-button-icon {
  height: 30px;
  padding-right: 10px;
}

#toggle-button-nav {
  display: flex;
  text-shadow: -.10px -.10px 0 black,
    .10px .10px black;

}

#toggle-button-nav>span {
  color: $primary;
  font-size: 1rem;
}

#menu_multilevelpushmenu i.fa {
  padding-right: 8px;
}

body.userrole-manager.userrole-owner, body.userrole-member  {
  .mlpm_w h2 {
    margin-top: 40px;
  }

  .mlpm_w li .levelHolderClass {
    top: 110px;
  }


  .mlpm_w .backItemClass a {
    position: relative;
    top: 56px;
    left: 16px;
    z-index: 999;
  }

}


.mlpm_w {
  position: absolute;
  min-height: 100%;
  margin: 0;
  background: $white;
  padding: 0;
  left: 0;
  top: 0;

}

.mlpm_w .levelHolderClass {
  position: fixed;
  overflow: hidden;
  overflow-y: auto;
  overscroll-behavior: contain;
  top: 0;
  bottom: 0;
  left: 0;
  background: $white;
  width: auto;
  zoom: 1;

  @media screen and (max-width:500px) {
    width: 360px !important;
    max-width: 360px !important;
    min-width: 360px !important;
  }
}

.mlpm_w li .levelHolderClass {
  top: 70px;
}



.mlpm_w h2 {
  padding: 0;
  margin: 0;
  line-height: normal;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #666;

}

.mlpm_w h2 a {
  padding: 0 0.4em;
}

.mlpm_w h2 i {
  font-weight: 600;
}

.mlpm_w h2:first-child>i {
  padding-left: 15px;
  float: right;
}

.mlpm_w h2:first-child>span {
  padding-left: 15px;
}

.mlpm_w h2 span {
  font-size: 16px;
  line-height: 1em;
  font-weight: 600;
  margin-right: 5px;
  text-transform: uppercase;

}

.mlpm_w ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.mlpm_w li {
  cursor: pointer;
  border-bottom: 1px solid #333;
  padding: 10px;
  width: 90%;
  margin: 0 auto;
  display: flex;
}

.mlpm_w li:last-child {
  border-bottom: none;
}

.mlpm_w li:hover {
  background-color: #ddd;
  color: $orange;
}

.mlpm_w li.small-link {
  border: 0px;
  padding-left: 10px;
}

.mlpm_w li.small-link a {
  font-size: 16px;
}

.mlpm_w li.small-link-top {
  padding-top: 25px;
  border-top: 1px solid $primary;
}

.mlpm_w a {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  outline: none;
  text-decoration: none;
  min-height: 40px;
  color: $primary;
  width: 100%;
  text-transform: uppercase;
  font-family: "OpenSansSemiBold", sans-serif;

  &:hover {
    color: $orange;
  }

}



.mlpm_w .backItemClass a,
.mlpm_w .backItemClass a i {
  color: $primary;
}

.mlpm_w .floatRight {
  float: right;
}

.mlpm_w .cursorPointer {
  cursor: pointer;
}

.mlpm_w .iconSpacing_ltr {
  padding: 0 0 0 4px;
}

.mlpm_w .iconSpacing_rtl {
  padding: 0 0 0 0.4em;
}

.mlpm_w .floatLeft {
  float: right;
  transform: rotateZ(180deg);
  background-color: $orange;
  color: $white;
  width: 35px;
  height: 35px;
  font-size: 40px;
  border-radius: 50%;
  position: relative;
  order: 2;

  &::before {
    position: absolute;
    top: -3px;
    left: 8px;
  }
}

#menu.show {
  display: block !important;
}


div.navbar.navbar-expand-lg {
  clear: both;
}

nav#portal-globalnav-wrapper {
  display: flex;
}

#logo_menu {
  display: flex;
  padding-bottom: 1px;
  align-items: flex-start;
  justify-content: flex-start;

}

.header_menu {
  margin-top: 40px;

  @media screen and (max-width:991px) {
    margin-top: 45px;
  }

  @media screen and (max-width:676px) {
    margin-top: 40px;
  }

  @media screen and (max-width:500px) {
    margin-top: 30px;
  }
}

#portal-logo {

  img {
    width: 330px;
  }

  @media screen and (max-width:700px) {
    // transform: translateX(35%) !important;
    transform: translateX(56%) !important;
    align-self:right;
    img {
      width: 330px;
    }

  }

  @media screen and (max-width:500px) {
    // transform: translateX(30%) !important;
    transform: translateX(54%) !important;

    img {
      width: 280px;
    }

  }

  @media screen and (max-width:450px) {
    // transform: translateX(25%) !important;
    transform: translateX(50%) !important;

    img {
      width: 265px;
    }

  }

  @media screen and (max-width:400px) {
      // transform: translateX(20%) !important;
      transform: translateX(41%) !important;

    img {
      width: 250px;
    }

  }

  @media screen and (max-width:370px) {
    // transform: translateX(20%) !important;
    transform: translateX(39%) !important;

  img {
    width: 250px;
  }

}
}

#portal-logo {
  transition: 0.4s;
  transition: all 0.4s ease-out;
}

body.userrole-manager, body.userrole-member {
  @media screen and (max-width:767px) {
    .portalheader-personalbar{
      padding-left: var(--plone-toolbar-width-collapsed);
    }
    a#toggle-button-nav{
      img.toggle-button-icon{
        padding-left: var(--plone-toolbar-width-collapsed);
      }
    }
  }
}


.portalheader-personalbar {
  display: flex;
  justify-content: flex-end;
  list-style: none;
  padding: 0;
  width:100%;
  @media screen and (max-width:767px) {
    justify-content:space-between;
  }

  body.userrole-manager, body.userrole-member {
    padding-left: var(--plone-toolbar-width-collapsed);
    
  }



  >li {
    margin-left: 30px;
    i {
      font-size: 25px;
    }
    @media screen and (max-width:767px) {
      justify-content: space-between;
      text-align: center;
      margin-left: 0px;
    }
  }

  button {
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    color:black;
    }

  a {
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    text-decoration: none;
    color:black;
  }
}

.mlpm_w h2 button.close-button {
  background-color: white;
  border: none;
  display: flex;
  align-items: center;
  left: -10px;
  position: relative;

  @media screen and (max-width:500px) {
    width: 44px;
    height: 44px;
  }

  i {
    font-size: 35px;

    @media screen and (max-width:500px) {
      position: absolute;
      top: 0px;
      right: 0px;
    }
  }
}

.hide-onload {
  display: none;
}

#menu .logo {
  width: 200px;
}

.ltr {
  margin-right: -100%;
  right: 0;
  -moz-box-shadow: 1px 1px 1px 1px #dfdfdf;
  -webkit-box-shadow: 1px 1px 1px 1px #dfdfdf;
  box-shadow: 1px 2px 1px 1px #dfdfdf;
}

.mlpm_w h2.level-title {
  position: relative;
  padding: 10px;
  padding-left: 20%;
  background-color: $braun;
  font-family: "OpenSansSemiBold", sans-serif;
  font-size: 14px;
  color: $white;
  min-height: 55px;
  text-transform: uppercase;

  @media screen and (max-width:500px) {
    padding-left: 25%;
  }
}

.mlpm_w .backItemClass a i {
  position: relative;
  top: 30px;
  left: 16px;
  width: 35px;
  height: 35px;
  font-size: 40px;
  border-radius: 50%;
  color: $white;
  background-color: $orange;

  &::before {
    position: absolute;
    top: -3px;
    left: 8px;
  }
}

.mlpm_w .backItemClass a {
  position: relative;
  top: 18px;
  left: 16px;
  z-index: 999;
}

.close-menu {
  @media screen and (max-width:500px) {
    display: none;
  }
}



// ul.nav-list {

//   >li {
//     display: none;
//   }

//   li[title~=Lehrszenarien],
//   li[title~=Medientechnik],
//   li[title~=Didaktisches],
//   li[title~=Organisation],
//   li[title~=Materialien],
//   li[title~=Aus],
//   li[title~=Community] {
//     display: list-item;
//   }
// }


.hs {
  margin: auto;
  display: flex;
  position: relative;
}

.hs a {
  color: $primary;
  text-decoration: none;
}

.hs a:hover {
  color: $primary;
}

.hs ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.hs ul li {
  float: left;
  margin-left: 0px;
  margin-right: 42px;
}

.linav:hover {
  a {
    color:black;
    text-decoration: underline;
    text-decoration-color: $orange;
    text-decoration-thickness: 2px;
    text-underline-offset: 2px;
    text-shadow: -.10px -.10px 0 black,
      .10px .10px black;
    }
}


.showcase {
  /* Set it so we could calculate the offsetLeft */
  position: relative;
  max-width: 1148px;
  max-height: 22px;
  /* Add scroll-bars */
  font-size: 16px;
  overflow: auto;
  float: left;
  /*style on hover*/
}

​ .showcase::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.showcase {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.left-arrow {
  border: 0;
  color: #29293a;
  height: 100%;
  position: absolute;
  top: 0;
  -webkit-transition: -webkit-transform 150ms ease-in-out;
  transition: transform 150ms ease-in-out;
  width: 20px;
  z-index: 1;
  left: 0;
  background: linear-gradient(to left, rgba(255, 255, 255, 0), rgb(255, 255, 255) 40%);
}

header .disabled {
  display: none;
}


.right-arrow {
  border: 0;
  color: #29293a;
  height: 100%;
  position: absolute;
  top: 0;
  -webkit-transition: -webkit-transform 150ms ease-in-out;
  transition: transform 150ms ease-in-out;
  width: 20px;
  z-index: 1;
  background: linear-gradient(to right, rgb(255 255 255 / 0%), #ffffff 30%);
  right: 0;
}

#fade {
  position: absolute;
}

.fade-out {
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s linear;
  @media screen and (max-width:767px){
    height: 0;
  }

}

.fade-in {
  opacity: 1;
  visibility: visible;
  transition: all 0.2s linear;
}

body.userrole-authenticated ul.register {
  li {
    display: none;
  }

  li:first-child {
    display: block;
  }

}

.universal-access{
  padding-left: 3%;
  padding-right: 3%;
  border: none;
  background: none;
  @media screen and (max-width:676px) and (min-width:300px) {
    padding-left: 16%;
    padding-right: 10%;
  }
}

div.menu-icon-wrapper {
  @media screen and (max-width:600px) {
    padding-right: 60px;
  }
}

div#portal-logo-wrapper {
  display: flex;
  justify-content: space-between;
  padding-bottom: 1rem;
  @media screen and (min-width:768px) {
    align-items: flex-end;
  }
  @media screen and (max-width:767px) {
    display: flex;
    flex-direction:column;
    gap:10px;
    padding-bottom: 0rem;
  }
}

body.userrole-manager, body.userrole-member {
  @media screen and (max-width:767px) {
    .portalheader-personalbar{
      padding-left: var(--plone-toolbar-width-collapsed);
    }
    a#toggle-button-nav{
      img.toggle-button-icon{
        padding-left: var(--plone-toolbar-width-collapsed);
      }
    }
  }
 
}

body.plone-toolbar-top.userrole-manager #portal-logo-wrapper {
  @media screen and (orientation:landscape) and (max-height: 300px) {
    top: 0;
  }
}

body.plone-toolbar-top.userrole-member #portal-logo-wrapper {
  @media screen and (orientation:landscape) and (max-height: 300px) {
    top: 0;
  }
}

.dropdown-backdrop {
  position: static !important;
}


