:root {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
}

$primary: #111213;
$orange: #D03D12;
$darkOrange:#D03D12;
$braun:  #833119;
$blue: #3B5E81;
$white: #fff;
$darkBlue: #131F2B;
$gray: #E9E9E9;
$dark-gray: #6c757d;
$filter: #707070;
$smdOrange: #F05F00;
$smdDarkOrange: #D34B00