* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body.plone-toolbar-top.userrole-anonymous {
  padding-left: 0;
}

ul li::marker {
  color: $orange;
  font-size: 1rem;
}

html,
body {
  width: 100%;
  height: 100%;
}

.main-container,
.col-spacing {
  padding-right: 40px !important;

  @media screen and (max-width:500px) {
    padding-right: 0 !important;
  }
}

#main-container {

  @media screen and (max-width:500px) {
    margin-bottom: 0px;
  }
}

#main-container .gallery {
  position: relative;
  z-index: 1;

  @media screen and (max-width:768px) {
    margin-top: 15px;
  }

  &::after {
    content: "";
    position: absolute;
    background: linear-gradient(0deg, rgba(39, 38, 42, 1) 0%, rgba(255, 255, 255, 0) 30%);
    z-index: 2;
    height: 100%;
    width: calc(100% - 24px);
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    top: 0;
    left: 12px;
  }

  h5 {
    position: absolute;
    bottom: 10px;
    left: 50%;
    color: $white;
    font-family: "OpenSansBold", sans-serif;

    transform: translateX(-50%);
    z-index: 999;
  }
}


body.portaltype-eteaching-policy-frontpage.site-eteaching,
body.portaltype-eteaching-policy-special.site-eteaching {
  aside#portal-column-two {
    @media screen and (max-width:991px) {
      // padding-left: 0;
      // padding-right: 0;
    }
  }

  aside#portal-column-two>h3~div {
    margin-bottom: 2rem;
  }

  aside#portal-column-two>a.portels-button {
    margin-bottom: 2rem;
    margin-top: 2rem;
  }

  section.studium-section h2 {
    line-height: 1.3;
    

  }

  section.media-section aside#portal-column-two {
    @media screen and (max-width:991px) {
      padding-left: 12px;
      padding-right: 12px;
    }
  }

  div.container+aside#portal-column-two {
    display: none;
  }

  #portal-column-two .portletWrapper .portletItem {
    display: none;

    &:nth-child(-n+3) {
      display: block;
    }
  }

  #content>header {
    display: none;
  }

  section#portal-column-content {
    width: 100%;
  }

  .welcome-section {

    @media screen and (max-width:991px) {
      flex-direction: column;
    }

    #portal-column-two .portletWrapper:last-of-type {
      display: none;
    }
  }

  .spacing-top {
    margin-top: 5rem;

    @media screen and (max-width:676px) {
      margin-top: 1rem;
    }
  }



  #portal-column-two .portletWrapper:first-of-type {
    div.card.portlet {
      border: none;
    }

    div.card-header {
      display: none;
    }



    .portletEvents .card-body>ul>li {
      padding: 1rem 0;
      border: none;

      a {
        color: $primary;
        text-decoration: none;
        font-family: "OpenSansSemiBold", sans-serif;
        font-size: 1.625rem;
        padding-bottom: 10px;
        line-height: 1.2;

      }
    }

    p.portlet-description {
      color: $primary;
      margin-top: 25px;
      margin-bottom: 0;
      line-height: 1.4;
    }

    span.portletItemDetails {
      font-family: "OpenSansSemiBold", sans-serif;
      color: $braun;
      text-transform: uppercase;

      .timezone {
        display: none;
      }

      .location {
        color: $primary;
        font-family: "OpenSansSemiBold", sans-serif;

        &::before {
          content: "|";
        }
      }
    }


    .portletEvents>.tile {
      color: $white;
      background-color: $primary;
      border-radius: 40px;
      text-decoration: none;
      padding: 9px 20px;
      position: relative;
      font-family: "OpenSansBold", sans-serif;
      max-width: 300px;


      i {
        position: absolute;
        right: 8px;
        top: 5px;
        background-color: $blue;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        font-size: 20px;

        &::before {
          position: relative;
          top: 5px;
          left: 10px;
        }
      }


      &:hover {
        background-color: $blue;

        i {

          background-color: $primary;

        }
      }
    }
  }

  section.welcome-banner {
    margin-bottom: 80px;
    margin-top: -5px;

    @media screen and (max-width:500px) {
      margin-bottom: 0px;
    }
  }

  section.media-section {
    #portal-column-two .portletWrapper:first-of-type {
      display: none;
    }
  }
}

body div.welcome-section aside#portal-column-two {

  @media screen and (min-width:991px) {
    width: 33.33333333%;
    flex: 0 0 auto;
  }
}


body.portaltype-eteaching-policy-frontpage aside#portal-column-two .portlet,
body.portaltype-eteaching-policy-special aside#portal-column-two .portlet{
  border: none;

  div.card-header {
    background-color: transparent;
    padding: 0;
    border: none;
    margin-top: -5px;

    a {
      text-decoration: none;
      color: $primary;
      font-size: 1.625rem;
      font-family: "OpenSansLight", sans-serif;
      text-transform: uppercase;

    }
  }

  .card-body>ul>li {
    border: none;
    padding: 1rem 0;

    a {
      color: $primary;
      text-decoration: none;
      font-family: "OpenSansSemiBold", sans-serif;
      font-size: 1.625rem;
      padding-bottom: 10px;
      line-height: 1.2;

    }

    span.portletItemDetails {
      color: $braun;
      font-family: "OpenSansSemiBold", sans-serif;

    }

    time {
      color: $braun;
      font-family: "OpenSansSemiBold", sans-serif;

    }


  }

  .card-footer {
    border: none;
    background-color: transparent;
    padding: 0;
    justify-content: flex-start;

    a {
      @extend .portlet-button;
      color: #fff !important;
      border-bottom: none !important;
    }
  }

  a.previous-events {
    display: none;
  }
}



div.media-cards>* {
  margin-bottom: 30px;
}


#main-container div.media-cards {
  h2.card-title {
    @media screen and (max-width:768px) {
      margin-top: 30px;
    }

    margin-bottom: 10px;
  }

  span {
    font-family: "OpenSansSemiBold", sans-serif;
  }

  span.time {
    color: #D03D12;
    font-family: "OpenSansSemiBold", sans-serif;
  }

  p {
    margin-top: 30px;
  }
}

.portal-section {
  .portal-column aside#portal-column-two {
    width: 100%;
  }
}

#main-container span.time {
  color: #D03D12;
  font-family: "OpenSansSemiBold", sans-serif;
}

section.media-section {
  margin-top: 40px;
}


section.cards-section {

  .portletWrapper:first-of-type {
    display: none;
  }

  .card-body {
    margin-top: 25px;

    h4 {
      margin-bottom: 5px;
    }

    >span {
      margin-bottom: 20px;
    }
  }
}

#main-container span.description {
  font-family: "OpenSansSemiBold", sans-serif;
  margin-bottom: 40px;
  margin-top: 30px;
  display: block;
}

.media-block {
  @media screen and (max-width:1200px) {
    flex-wrap: wrap;

    >div {
      margin-top: 15px;
    }
  }

}

section.studium-section img {
  @media screen and (max-width:989px) {
    margin-top: 30px;
  }
}

section.studium-section .spacing-right img {
  @media screen and (max-width:500px) {
    margin-top: 0px;
  }
}



.media-list {
  gap: 15px;
}

.studium-section {
  gap: 20px;

}

.media-list h4 {
  line-height: 1.3;
}

#main-container .supporting-information {
  font-family: "OpenSansSemiBold", sans-serif;

}

.hide-result {
  display: none;
}

.section-title {
  margin-bottom: 2rem;
}

.text-description {
  margin-top: 30px;
}

.spacing-bottom {
  margin-bottom: 30px;

  @media screen and (max-width:500px) {
    margin-bottom: 20px;
  }
}

.spacing-right {
  padding-right: 40px !important;

  @media screen and (max-width:500px) {
    padding-right: 15px !important;
  }
}

div.card-wrapper {
  @media screen and (max-width:500px) {
    padding-left: 0;
    padding-right: 0;

  }
}

#portal-column-two {
  @media screen and (max-width:500px) {
    margin-top: 30px;

  }
}

.mobile-spacing {
  @media screen and (max-width:500px) {
    padding-left: 12px;
    padding-right: 12px;
  }

}

.pagination {
  flex-wrap: wrap;
}

#main-container ul.pagination li.page-item {
  margin-bottom: 5px;
}

body.plone-toolbar-top {
  div.managedViewlets {
    span.toolbar-label {
      display: block;
    }
  }
}

#visual-portal-wrapper.plone-toolbar-top #edit-zone .dropdown-menu.show {
  @media screen and (max-width:500px) {
    margin-left: 10px !important;
  }
}


.container, .container-md, .container-sm {
  @media screen and (max-height: 500px){
    max-width: 100% !important;
  }
}

.searchResults {
  .list-group-item {
    border: none;
    margin-bottom: 20px;
  }

  .result-title {
    font-size: 1.6em !important;
    margin-bottom: 20px;
    word-break: break-all;

    a {
      border-bottom: none !important;
    }
  }
}

.list-group-numbered>.list-group-item::before {
  content: none !important;
}

.flex-fill {
  a {
    text-decoration: none;
  }

  .badge {
    background-color: transparent;
    color: $orange;
    text-transform: uppercase;
    --bs-badge-padding-x: 0;
    --bs-badge-padding-y: 0;
  }
  .d-flex {
    text-transform: uppercase;
  }

  .ps-2 {
   padding-left: 0;
  }
}

  .nav-item {
    text-transform: uppercase;
  }

.searchPage {
  .d-flex {
    flex-wrap: wrap;
  }
}

.carousel-control-next-icon, .carousel-control-prev-icon{
  padding: 32px;
  font-size: 0rem !important;

  @media screen and (max-width: 500px){
    padding: 15px;
  
}
}

#qualtrics-area {

.qualtrics-btn {
  background-color: $orange;
  color: white;

  &:hover {
    background-color: $darkOrange;
    color: white;
  }
}
}


.show-more-container {

  a {
	color: $primary;
	text-decoration: none;
	border-bottom: 1px solid;

	&:hover {
	  border-bottom: 1px solid $orange !important;
	  color: $orange
	}
  }
}


.slidearea {

  #new-player.embed #player-toolbar  {
  height: 50px;
  opacity: 1;
  display: flex;
  background-color: #efefef !important;
  color: #212240;
  padding-top: 8px;
  margin-top: 0;
  position: relative;
}

}

.underline-link {
	color: $primary;
	text-decoration: none;
	border-bottom: 1px solid;

	&:hover {
	  color: $orange;
	}
}

.plain-link {
	color: $primary;
	text-decoration: none;
	border-bottom: none !important;

	&:hover {
	  border-bottom: 1px solid $orange !important;
	  color: $orange
	}

}

.plain-plain-link {
    font-family: "OpenSans",sans-serif !important;
    color: #111213 !important;
    text-decoration: none !important;
    border-bottom: none !important;

}

.alert a {
    font-family: "OpenSansSemiBold",sans-serif !important;
    color: #000 !important;
    text-decoration: none !important;
    border-bottom: none !important;
    font-weight: normal !important;
    
}

.alert-secondary {
    color: #000 !important;
}

.alert-secondary a::after {
 background-color: #d03d12 !important;
}

p > a:not(.list-group-item):not(.user-profile) a {
	font-family: "OpenSans", sans-serif;
	color: $primary;
	text-decoration: none;
	border-bottom: 1px solid;

	&:hover {
	  color: $orange;
	}
}

p > a:hover :not(.list-group-item, .user-profile) {
	font-family: "OpenSans", sans-serif;
	color: $primary;
	text-decoration: none;
	border-bottom: 1px solid;

	&:hover {
	  color: $orange;
	}
}

b, strong {
	font-family: "OpenSansSemiBold",sans-serif !important;
  font-weight: normal !important;
}

/* From the old system */

.line-dotted {
    border-top: 2px dotted #e7e7e7;
    width: 100%;
}

div.imgbox-out {
    padding-top: 1em;
    page-break-inside: avoid;
    clear: both;
}

div.imgbox-left {
    float: left;
    margin-right: 1em;
    margin-bottom: 1em;
    width: 30%;
    background-color: #e7e7e7;
}

