div#gallery-viewlet {
    .spacing-top {
        margin-top: 30px;
    }

    .viewlet-gallery {
        margin-bottom: 10px;

        img {
            max-width: 100%;
            height: auto;
        }

        &.right {
            float: right;
            margin-left: 20px;
            width: 30%;
        }

        &.left {
            float: left;
            margin-right: 20px;
            width: 30%;
        }

        &.full-width {
            width: 100%;
        }
    }

}

div#audio-viewlet {
    .spacing-top {
        margin-top: 30px;
    }

    .audio.full-width {
        width: 100%;
        margin-bottom: 20px;
    }

}

div#image-viewlet {
    .image {

        .caption-container {
          
          padding: 10px;
        }

        p {
            background-color: $gray;
        }

        img {
            width: 100%;
            display: block;
            height: auto;
            max-width: 100%;
        }

        &.right {
            float: right;
            margin-left: 20px;
            width: 33%;
        }

        &.left {
            float: left;
            margin-right: 20px;
            width: 33%;
        }

        &.full-width {
            float: left;
            margin-right: 0px;
            width: 100%;
        }
    }

    .image-caption {
        font-size: 0.9rem;
        padding: 5px;
    }
}

.media-video {
    width: 100%;
    border: solid 2px rgb(192, 192, 192);
    padding: 10px;
}

.pdf-container {
    clear: both;

    .title {
        font-family: "OpenSansLight", sans-serif !important;
    }

    h2 {
       
        font-size: 1.625rem !important;
    }

    a {
        text-decoration: none;
        color: $primary;

        div {
            padding: 10px;

            &:hover {
                background-color: $gray;
            }
        }
    }

    .cta-pdf {
        text-decoration: none;
        border-bottom: 1px solid #d03d12;
        color: black;
        font-weight: 600;
    }

    .pdf-border {
       img {
        width: 400px;
        
       }
    }
}

div.slideshare-viewlets {
    div.slidearea {
        margin-bottom: 30px;
            div.slidearea {
                margin-bottom: 30px;
                background-color: red;
          
                iframe {
                    width: 100% !important;
                }
            }
            
    }

    div.slidearea-wrapper {
        padding: 20px;
        border: 1px solid #ddd;
        color: grey;

        .btn-community {
            color: #ffffff;
            background-color: $orange;
            border-color: #cccccc;
            font-weight: bold;

            &:hover {
                background-color: $darkOrange;
            }
        }

        a {
			color: $primary;
			text-decoration: none;
			border-bottom: 1px solid;

			&:hover {
			  color: $orange;
			}
        }
    }
}

.qualtrics-embed {
    .btn-qualtrics {
        color: #222222;
        background-color: #ffffff;
        border-color: #cccccc;

        &:hover {
            background-color: $orange;
            border-color: black;
        }
    }

}

.table-contents {
    color: $orange;

    h2 {
        color: black;
    }

    ul {
        list-style: none;
    }

    li {
        margin-bottom: 10px; 
    }

    .heading2 {
        margin-left: 10px;
        font-weight: bold;
        line-height: 2em;
    }

   

    a {
		font-family: "OpenSans", sans-serif;
		color: $primary;
		text-decoration: none;
		border-bottom: 1px solid;

		&:hover {
		  color: $orange;
		}
    }

    .heading1 {
        font-weight: bold;
        line-height: 2em;
    }

    hr {
        border-top: 0.15rem solid $orange;
        width: 100%;
    }

    .accordion-button {
        background-color: #efefef;
        font-weight: bold;
    }

    .accordion-button:not(.collapsed) {
        background-color: #efefef;
        font-weight: bold;
    }

    .accordion-body {
        background-color: white;
    }

    .accordion-button:not(.collapsed)::after {
        background-image: var(--bs-accordion-btn-icon);
    }   
}

.related_object_listing {


    span {
        color: $orange;
        font-weight: bold;
    }
}

.media-element {
    background-color: $gray;
    text-align: center;

    .audio {
        width: 100%;
    }

    audio::-webkit-media-controls-panel {
        background-color: $gray;
    }

}

.listing-locations {
    margin-top: 20px;

    h1 { 
        font-family: OpenSansLight, Verdana, Arial !important;
        font-size: 1.6em !important;
    }

    p {
        margin-bottom: 3px;
        font-family: OpenSansRegular, Verdana, Arial !important;
    }

    a {
        text-decoration: none;
        border-bottom: 1px solid #d03d12;
        color: black;
        font-weight: 600;
    }
    
    div {
        p:first-of-type {
            font-family: "OpenSansSemiBold",sans-serif !important;
            font-size: 1.625rem !important;
          }
    }
}

.listing-journals {

    h1 {
        font-family: "OpenSansLight", sans-serif !important;
        font-size: 1.6em !important;
    }

    .title {
        font-family: "OpenSansSemiBold", sans-serif !important;
        font-size: 1.625rem !important;
    }

    .btn-container {
        text-align: center;
    }

    .btn-journal {
        color: #fff;
        background-color: #bb5d00;
        border-radius: 40px;
        text-decoration: none;
        padding: 8px 20px;
        position: relative;
        display: block;
        text-transform: capitalize;
        font-family: "OpenSansSemiBold",sans-serif;
        width: 200px;
        margin: auto;
        text-align: left;
        &:hover {
            background-color: #9c4e00;
            
        }
    i {

    position: absolute;
    right: 8px;
    top: 4px;
    background-color: #804200;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    font-size: 20px;
    padding: 5px;
    padding-left: 9px;
    }
    }
}

#viewlets-abovecontentbody {
    >div {
        margin-top: 30px;

        h1 {
            padding-bottom: 15px;
        }
    }

    .collapsible-item {
      line-height: 2em;
    }
}

#experiencereport-viewlet {
    margin-top: 15px;
    margin-bottom: 15px;

    >div.list-group-items>div.list-group-item {
        margin-bottom: 15px;

        .item-header {
            margin-bottom: 15px;

            i {
                margin-right: 5px;
                color: $orange;
            }
        }
    }
}

#media-documents {
    .list-group-item {
        margin-top: 15px;
        margin-bottom: 15px;
        border-bottom: 1px dotted #222222;
        padding-bottom: 15px;
        padding-top: 15px;

        a.list-group-item-link {
            color: #222222;
            text-decoration: none;
        }

        .list-group-item-image {
            margin-bottom: 15px;
        }
    }
}

#listing-viewlet {
    .viewlet-wrapper {
        margin-top: 20px;
        padding-top: 20px;
        border-top: 1px dotted #222222;
        margin-bottom: 20px;
        padding-bottom: 20px;
        border-bottom: 1px dotted #222222;

        .viewlet-items {
            margin-top: 20px;

            a {
                color: #000;
                text-decoration: none;
                border-bottom: 1px solid $orange;
                font-family: "OpenSansBold", sans-serif !important;
            }
        }
    }
}

#more-goodpracticedatabases,
#media-documents,
#experiencereport-viewlet {
    margin-top: 30px;
    margin-bottom: 30px;
    .cards-section {
        margin-top: 30px;
        margin-bottom: 30px;
    }

    a {
        color: #222222;
        text-decoration: none;
    }

    .more {
        color: #000;
        text-decoration: none;
        border-bottom: 1px solid;
        font-family: "OpenSansBold", sans-serif !important;
    }

    a.portels-button {
        background-color: #131F2A !important;
    }

    .item-header {
        margin-bottom: 15px;
        margin-top: 15px;

        i {
            margin-right: 5px;
            color: $orange;
        }
    }
}

div#copyright-info{
    // div,span {font-size:10px !important;}
    a{
		font-family: "OpenSans", sans-serif;
		color: $primary;
		text-decoration: none;
		border-bottom: 1px solid;

		&:hover {
		  color: $orange;
		}
    }
}

.bg-light {
    background-color: transparent !important;
    padding: 0 !important;
    padding-right: 4px !important;
}

.text-muted {
    .section-heading {
        font-size: 1.625rem;
        font-family: "OpenSansLight", sans-serif;
        color: black;
    }
}

.pull-left {
    float: left;
}

.pull-right {
    float: right;
}

.card-img-top {
    border: 1px solid lightgray;
}

.related_material {
    margin-top: 20px;
    margin-bottom: 10px;
}

a.external, a[data-linktype="external"]{
	font-family: "OpenSans", sans-serif;
	color: $primary;
	text-decoration: none;
	border-bottom: 1px solid;

	&:hover {
	  color: $orange;
	}
}

.site-actions-date {
    margin-right: 32px;
    margin-left: -20px;
    position: relative;
    top: -25%;

    @media screen and (max-width: 500px){
        margin-right: 20px;
    }
}


.toggle-text {
    display: none !important;
  }
  
.toggle-text-inline {
    display: inline !important;
}

.author_category{
    padding-bottom:20px;
}


#main-container {
    #viewlet-info_boxes{
        clear: both;
        width: 100%;
        background: #e9e9e9 0% 0% no-repeat padding-box;
        svg {
            color:orange;
            margin-right:5px;
        }
    }
    div.info_box{
        margin-left: 20px;
        margin-right: 20px;
        margin-top: 20px;
        text-align: left;
        display:inline-block;
        font-size: 16px;
        font-family: "OpenSansBold", sans-serif;
        p {
            margin-left: 25px;
        }
        a{
			font-family: "OpenSans", sans-serif;
			color: $primary;
			text-decoration: none;
			border-bottom: 1px solid;

			&:hover {
			  color: $orange;
			}
        }
    }
}

div.slideshare-viewlets {
    div.slidearea {
        margin-bottom: 30px;
  
        iframe {
            width: 100% !important;
        }
    }
    }
  
.newsletter-subscription {
    background-color: $gray;
    padding: 40px;

    a {
		font-family: "OpenSans", sans-serif;
		color: $primary;
		text-decoration: none;
		border-bottom: 1px solid;

		&:hover {
		  color: $orange;
		}
    }
}

#new-player.embed #player-toolbar {
    height: 50px;
    opacity: 1;
    display: flex;
    background-color: #efefef !important;
    color: #212240;
    padding-top: 8px;
    margin-top: 0;
    position: relative;
}

.collapsible-list {
    background-color: transparent;

    .collapsible-section {
      background-color: transparent;
      
    }

     a {
        color: #111213;
        font-weight: bold;
        text-decoration: none;
        border-bottom: 1px solid;
        
     }

     .accordion-button:not(.collapsed) {
        background-color: #efefef00;
        box-shadow: none;
        &:hover {
            border-color: #efefef; 
        } &:focus {
            border-color: #efefef; 
        }
     }

     button {
        font-weight: bold;
        color: black !important;
        background-color: transparent;
     }

     .accordion-header {
        background-color: #efefef;
        border-top: 20px;
        position: relative;
        width: 100%;

        .plus-sign {
            position: absolute;
            right: 2%;
            top: 29%;
            font-size: 20px;
        }

        .minus-sign {
            position: absolute;
            right: 2%;
            top: 29%;
            font-size: 20px;
        }
     }
}

.hide-icon {
 transition: 3s;
 display: none !important;
 
}



.copyright-viewlet {
  font-size: 12px !important;

  .logo-link {
    border-bottom: none !important;
  }

  .body-text {
    font-size: 12px !important;
  }
}

.media-gallery-icon-container {
    background-color: $orange;
    padding: 15px;
    border-radius: 60px;
    font-size: 0rem !important;

    @media screen and (max-width: 500px){
        padding: 7px;
        border-radius: 41px;
    }
}

.qualtrics-viewlet {
    iframe {
        height: 1600px !important;
    }
}

.site-actions-hr {
	border-top:
	dotted 1px;
}

.site-actions-container {
    display: flex;
    flex-wrap: nowrap;
}

.learning-map-height {
    height: 100%;
    width: 100%;
}

.article-image {
    a {

    text-decoration: none;
    font-weight: bold;
    color: black;

        img {
            max-width: 100%;
            object-fit: contain;
        }
        }
    }

    .heading-hide {
        display: none;
    }

.article-description {
    a {
    text-decoration: none;
    color: black;

    }
}

.related_object_listing {
    .img-responsive {
        @media screen and (max-width: 500px){
            width: 30%;
            object-fit: contain;
        }
    }

    a {
        h4 {
            &:hover {
                color: #3b5e81 !important;
            }
        }
        &:hover {
            color: #3b5e81 !important;
        }
    }
}

div.card-body>ul>li a.title, a.tile{
    display: table !important;
}

.user-profile {
    .img-container {
        padding: 10px;
        width: 180px;

        .user-profile-img {
            border-radius: 50%;
            height: 150px;
            object-fit: cover;
        }
    }

    span {
    // font-family: "OpenSansSemiBold", sans-serif !important;
    font-size: 1.1rem !important;
    }

    a {
		font-family: "OpenSans", sans-serif;
		color: $primary;
		text-decoration: none;
		border-bottom: 1px solid;

		&:hover {
		  color: $orange;
		}
    }
    
    a:hover {
    		color: #111213;
    }
}

div.card-header{
    background-color: #FAECE7;
    a{
        border-bottom:none !important;
    }
    border-radius: 0 !important;
}

div.card{
    border-radius: 0;
}

nav.nav-tabs{
    border-width: 0;
}

span.nav-item, nav.nav-tabs{
    a.nav-link {
    border:none;
    }
}

a.list-group-item{
    border:none;
}

.show-more {
    display: initial;
    cursor: pointer;
    border-bottom: 1px solid;
	
	&:hover {
	  border-bottom: 1px solid $orange !important;
	  color: $orange
	}
}

[open] summary {
    position: absolute;
    bottom: -1.5em;
    left: 0;
  }
  
  summary::before {
    content: "Mehr anzeigen";
  }
  
  [open] summary::before {
    content: "Weniger anzeigen";
    display: none;
  }
  
  details {
    display: contents;
  }
  
  .more-text {
    display: block !important;
    position: relative;
    margin-bottom: 2em;
  }

  #map {
    z-index: 0;
  }

  div.map {
    height: 100%;
  }

.top5{margin-top:5px} 
.top10{margin-top:10px}
.top20{margin-top:20px}
.top30{margin-top:30px}
.top40{margin-top:40px}

.bottom5{margin-bottom:5px} 
.bottom10{margin-bottom:10px}
.bottom20{margin-bottom:20px} 
.bottom30{margin-bottom:30px} 
.bottom40{margin-bottom:40px}
